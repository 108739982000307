<template>
  <div id="thailandZotaPay">
    <div class="content_box">
      <div class="inner_max">
        <div class="page-header">{{ $t('menu.depositFund') }}</div>
        <div class="form_deposit">
          <h4>{{ $t('deposit.thailand.header') }}</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/local_transfer.png" alt="" />
              <p>{{ $t('deposit.method.desc', { method: $t('deposit.thailand.header') }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>{{ $t('deposit.method.inst2', { number: '2', country: $t('deposit.thailand.country') }) }}</li>
                <li>{{ $t('deposit.method.inst3', { number: '3' }) }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.method.form.header', { method: $t('deposit.thailand.header') }) }}</p>
              <el-form label-position="top" :model="thaiForm" ref="thaiForm" status-icon :rules="thaiRules">
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        supportedCurrencies="USD"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="thaiForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="thaiForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <p class="mb-2">
                        <span class="required_icon">*</span>
                        {{ $t('deposit.default.rate.rate', { oldCurrency: 'USD', newCurrency: 'THB' }) }}
                        <span>{{ rate }}</span>
                      </p>
                      <p>
                        <span class="required_icon">*</span> THB: <span>{{ rateChange }}</span>
                      </p>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import mixin from '@/mixins/page/deposit';
import thailandMixin from '@/mixins/page/deposit/thailand';
import { apiZotapay_payment } from '@/resource';

export default {
  name: 'ThailandZotaPay',
  components: { NumericInput, AccountNumber },
  mixins: [mixin, thailandMixin],
  data() {
    return {
      maxLimit: 500000
    };
  },
  methods: {
    submitForm() {
      this.$refs['thaiForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              this.depositSubmitWithRate(result, this.success);
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiZotapay_payment(
        {
          mt4Account: this.thaiForm.accountNumber,
          operateAmount: this.thaiForm.amount,
          applicationNotes: this.thaiForm.notes,
          paymentChannel: this.paymentChannel,
          depositAmount: this.rateChange,
          rate: this.rate
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
